import * as Sentry from "@sentry/gatsby";

Sentry.init({
  dsn: "https://ccccaf833f8ecf0e64bcb6c2d45c319a@o1076897.ingest.us.sentry.io/4506581740093440",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for tracing.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.1,

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 1.0,
});
